import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { DateTime } from 'luxon'
import { ComponentPropsWithoutRef } from 'react'

import { colors } from '@/theme/variables'

import { NewsArticleBody } from './NewsArticleBody'

interface Props extends ComponentPropsWithoutRef<'article'> {
  data: Queries.NewsArticleFragment | null
  layout: 'LIGHTBOX' | 'PAGE'
}

export const NewsArticle = ({
  data,
  layout,
  ...props
}: Props): JSX.Element => {
  const styles = {
    article: css`
      background: #fff;
      color: ${colors.navy};
      padding: var(--row-72) 0;
      --max-width: 100ch;
      ${layout === 'PAGE' &&
      css`
        padding-bottom: var(--row-108);
      `}
    `,
    header: css`
      border-bottom: 1px solid ${colors.navyLight};
      margin: 0 var(--margin) var(--row-36);
      max-width: var(--max-width);
    `,
    heading: css`
      font-family: var(--ff-good-wide);
      font-weight: 700;
      font-size: var(--fs-36);
      margin: 0.5em 0 0.75em;
    `,
    articleInfo: css`
      font-family: var(--ff-fairplex);
      text-transform: uppercase;
      color: ${colors.blueMid};
    `,
    category: css`
      display: inline-block;
      font-weight: 500;
    `,
    publishedDate: css`
      display: inline-block;
    `,
  }
  return (
    <article
      css={styles.article}
      {...props}
    >
      <header css={styles.header}>
        <div css={styles.articleInfo}>
          <div css={styles.category}>
            {data?.category?.name}&emsp;&nbsp;
          </div>
          <div css={styles.publishedDate}>
            {DateTime.fromISO(data?.publishedDate || '').toFormat(
              'MMMM dd, yyyy'
            )}
          </div>
        </div>
        <h1 css={styles.heading}>{data?.headline}</h1>
      </header>
      {data?.content?.__typename === 'DatoCmsNewsArticleBody' && (
        <NewsArticleBody data={data?.content} />
      )}
    </article>
  )
}

export const NewsArticleFragment = graphql`
  fragment NewsArticle on DatoCmsNewsArticle {
    __typename
    id: originalId
    headline
    publishedDate
    category {
      ...NewsCategory
    }
    thumbnailImage {
      alt
      gatsbyImageData(
        width: 720
        imgixParams: { ar: "4:3", fit: "crop", crop: "focalpoint" }
      )
      ...ImageFocalData
    }
    content {
      ... on DatoCmsUrl {
        __typename
        id: originalId
        url
      }
      ... on DatoCmsPdf {
        __typename
        id: originalId
        pdf {
          localFileId
        }
      }
      ... on DatoCmsNewsArticleBody {
        ...NewsArticleBody
      }
    }
    slug
    seoMetaTags {
      tags
    }
  }
  fragment NewsCategory on DatoCmsNewsCategory {
    id: originalId
    __typename
    name
  }
`
